<template>
    <v-container fluid>
        <!-- Shopify.com Integration Page -->
        <v-card class="rounded d-flex flex-row flex-lg-wrap">
            <v-row class="ma-0">
                <v-col>
                    <v-card color="transparent" elevation="0" style="padding: 10px; text-align: center !important">
                        <v-card-text style="margin-bottom: 25px">
                            <div align="center" style="margin-bottom: 10px">
                                <h1 style="margin-bottom: 40px">GoDial + Shopify</h1>
                            </div>
                            <div style="padding: 5px 15px; text-align: center">
                                <div style="text-align: left">
                                    <p style="
                              margin-top: 20px;
                              font-size: 18px;
                              font-weight: 600;
                              margin-bottom: 10px;
                            ">
                                        How to integrate Shopify with GoDial?
                                    </p>
                                    <p>
                                        1. Create a list in GoDial. Goto CRM > New list
                                        <br />
                                        2. Connect your Shopify account with GoDial by selecting
                                        the list where you want to receive leads.
                                        <br />
                                        3. Now Go to your Shopify Store > Settings > Notification > Webhooks > Create
                                        Webhook
                                        <br />
                                        4. Now copy the below URL and paste it in the URL section and change Event to
                                        "Order creation" and click on save.
                                    </p>
                                </div>
                            </div>
                        </v-card-text>
                        <v-footer color="transparent" absolute>
                            <v-flex style="text-align: center !important">
                                <p style="
                            font-size: 12px;
                            opacity: 0.9;
                            margin-bottom: 0px;
                          ">
                                    Feel free to reach out to us at
                                    <a href="mailto:support@godial.cc">support@godial.cc</a>
                                    if you are interested in these features
                                </p>
                            </v-flex>
                        </v-footer>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card flat>
                        <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
                            <v-layout row wrap>
                                <v-flex d-flex justify-center class="mt-10" style="padding-top: 22px">Enable Shopify
                                </v-flex>
                                <v-flex class="mt-10" d-flex justify-center>
                                    <v-switch v-model="shopify.enabled" color="primary"></v-switch>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-card>
                    <v-card color="transparent" elevation="0"
                        style="margin-top:25px; padding: 15px 50px; text-align: center">
                        <v-card-title primary-title class="justify-center">Shopify Credentials</v-card-title>

                        <v-flex xs12 sm12 offset-sm1 md12 offset-md1>
                            <v-autocomplete v-model="shopify.selectedList" :disabled="!shopify.enabled" class="pt-1"
                                :items="lists" item-text="name" item-value="id" label="Select List" return-object
                                prepend-inner-icon="view_list" autocomplete="false" outlined dense />
                        </v-flex>

                        <v-flex xs12 sm12 lg12 offset-sm1 md12 offset-md1 style="margin-bottom: 10px">
                            <v-btn color="primarygrad" dark block @click="setShopify">Save</v-btn>
                        </v-flex>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
        <v-card v-if="shopify.enabled && shopify.selectedList.id" class="integration-card my-3 pa-2">
            <v-alert color="primary" icon="mdi-alert" theme="dark" prominent class="mt-2">
                Please copy these details and put it in your Shopify Store
                Webhook and leads will start
                coming to the list selected by you.
            </v-alert>
            <v-card-title primary-title class="integration-title">
                Activate Integration
            </v-card-title>

            <v-card-text class="integration-body">
                <v-row>
                    <v-col>
                        <v-card flat class="integration-details">
                            <!-- Endpoint -->
                            <v-row>
                                <v-col>
                                    <p class="integration-label">Endpoint:</p>
                                    <p ref="curlTextarea" class="integration-value">
                                        {{ ENDPOINT }}/externals/shopify?access_token={{ token }}
                                    </p>
                                </v-col>
                            </v-row>

                            <!-- Method -->
                            <v-row>
                                <v-col>
                                    <p class="integration-label">Method:</p>
                                    <p class="integration-value">POST</p>
                                </v-col>
                            </v-row>

                            <!-- Headers -->
                            <v-row>
                                <v-col>
                                    <p class="integration-label">Headers:</p>
                                    <p class="integration-value">
                                        Content-Type: application/json
                                    </p>
                                </v-col>
                            </v-row>

                            <v-row class="my-0">
                                <v-col>
                                    <v-btn color="primary" @click="copyToClipboard">
                                        Copy to Clipboard
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "../../js/firebase";

const _ = require("lodash");

export default {
    data() {
        return {
            shopify: {
                enabled: false,
                selectedList: {},
            },
            lists: [],
            token: "",
        };
    },

    computed: {
        ...mapGetters(["ENDPOINT", "companyDetails", "selectedTeam"]),
    },
    async mounted() {
        this.generateToken();
        await this.fetchLists();
        await this.getIntegrationDetails();
    },
    methods: {
        copyToClipboard() {
            const curlCommand = this.$refs.curlTextarea.innerText;
            navigator.clipboard.writeText(curlCommand).then(() => {
                this.$swal({ type: "success", text: "Copied to clipboard!" });
            }).catch((err) => {
                console.error("Failed to copy!", err);
            });
        },
        async setShopify() {
            try {
                let resp = await this.$http.get(
                    `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.companyDetails.id}&filter[where][type]=shopify`
                );
                let respo;
                if (resp.data && resp.data.length && resp.data[0].id) {
                    respo = await this.$http.patch(`${this.ENDPOINT}/integrations/${resp.data[0].id}`, {
                        config: {
                            listId: this.shopify.selectedList.id,
                        },
                    });

                    console.log("updated response: ", respo);
                } else {
                    respo = await this.$http.post(`${this.ENDPOINT}/integrations`, {
                        type: "shopify",
                        enabled: true,
                        companyId: this.$store.getters.companyDetails.id,
                        config: {
                            listId: this.shopify.selectedList.id,
                        },
                    });
                }

                if (respo.data.id) {
                    this.$swal({
                        type: "success",
                        text: "Configuration Saved Successfully",
                    });
                } else {
                    this.$swal({
                        type: "error",
                        text: "An error occurred! Please try again.",
                    });
                }
            } catch (error) {
                console.log("Shopify.com integration setting error: ", error);
                this.$swal({ type: "error", text: error.body.error.message });
            }
        },
        async fetchLists() {
            try {
                this.lists = [];
                let teamIds = this.getTeamIds();
                let response = await this.$http.post(`${this.ENDPOINT}/lists/fetch`, {
                    teams: teamIds,
                });
                this.lists = response.body;

                if (this.lists.length < 1) {
                    this.loader = false;
                }
            } catch (e) {
                console.log("Error in list fetch", e);
            }
        },
        async getIntegrationDetails() {
            try {
                let resp = await this.$http.get(
                    `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.companyDetails.id}&filter[where][type]=shopify`
                );
                console.log("resp.data: ", resp.data)
                if (resp.data && resp.data.length && resp.data[0].id) {
                    const chosenList = this.lists.find(
                        (item) => item.id === resp.data[0].config.listId
                    );
                    this.shopify.selectedList = chosenList;
                    this.shopify.enabled = true;
                }
            } catch (error) {
                console.log("getting integration error in shopify: ", error)
            }

        },
        generateToken() {
            this.$http
                .get(
                    `${this.ENDPOINT}/externals/token?access_token=${this.$store.getters.user.token}`
                )
                .then((response) => {
                    this.token = response.body.id;
                })
                .catch((err) => {
                    this.$swal({ type: "error", text: err.body.error.message });
                });
        },
        getTeamIds() {
            let teams = [];
            if (this.selectedTeam == "allTeams") {
                teams = _.map(this.teams, "id");
            } else {
                teams.push(this.selectedTeam);
            }
            return _.without(teams, "allTeams");
        },
    },
};
</script>

<style>
.integration-card {
    background-color: #6a1b9a;
    color: white;
    border-radius: 10px;
}

.integration-title {
    font-size: 24px;
    font-weight: bold;
}

.integration-body {
    background-color: white;
    color: black;
    padding: 20px;
    border-radius: 10px;
}

.integration-label {
    font-weight: 600;
    color: black;
    font-size: 14px;
    display: inline;
    margin-block: 0.3rem;
}

.integration-value {
    font-size: 14px;
    color: #000000;
    display: inline;
    margin-inline: 0.5rem;
}

.integration-textarea {
    font-size: 12px;
    color: #000000;
    height: auto;
}

.warning-msg {
    color: red;
    font-size: 0.7rem;
    display: block;
}

.choose-list {
    font-weight: 500;
    margin-bottom: 0.5rem 0 0.3rem;
}
</style>